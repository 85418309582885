<template>
  <v-card elevation='0' align='center' class='tilecard'>
    <h1>
      <v-icon style='font-size:1em; color:inherit'>{{vIcon}}</v-icon>
    </h1>
    <h3>{{title}}</h3>
    <p>
      {{body}}
    </p>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    vIcon: {
      type: String
    },
    body: {
      type: String
    }
  }
}
</script>

<style scoped>
  .tilecard {
    height:200px
  }
  .tilecard h3, .tilecard h1{
    color: #28612C
  }
  .tilecard p {
    color: gray
  }
</style>
