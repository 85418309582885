import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import i18next from './langs/i18next'
import VueI18Next from '@panter/vue-i18next'

Vue.config.productionTip = false

Vue.use(VueI18Next)
const i18n = new VueI18Next(i18next)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
