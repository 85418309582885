import i18next from 'i18next'
import en from './en'

i18next.init({
  lng: 'en',
  resources: {
    en: {
      label: 'English',
      translation: {
        ...en
      }
    }
  }
})

export default i18next
