import dayjs from 'dayjs'

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    progress: { progress: 0, length: 0 }
  }),
  actions: {
    async uploadFiles ({ commit }, { files }) {
      commit('setLoading', true)

      const body = new FormData()
      for (const file of files) {
        body.append('file', file)
      }

      const secondsPerFile = 0.4158602150537634
      let counter = 0
      const intervalConstant = setInterval(async () => {
        commit('setProgress', { progress: Math.min(++counter, files.length + 1), length: files.length })
      }, secondsPerFile * 1000)

      const response = await fetch('/rest/upload-pdf/', {
        method: 'POST',
        body: body,
        enctype: 'multipart/form-data'
      })

      clearInterval(intervalConstant)
      commit('setProgress', { progress: files.length, length: files.length })

      setTimeout(async () => {
        if (response.ok) {
          const blob = await response.blob()
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = 'Invoice2Excel_Export_' + dayjs().format('DD.MM.YYYY_HH:mm') + '.xlsx'
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }

        commit('setLoading', false)
      }, 1000)
    }
  },
  mutations: {
    setLoading (state, value) {
      state.loading = value
      if (value === true) {
        state.progress = { progress: 0, total: 0 }
      }
    },
    setProgress (state, value) {
      state.progress = value
    }
  }
}
