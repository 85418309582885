<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click="showDrawer = !showDrawer" v-if="$vuetify.breakpoint.xs"></v-app-bar-nav-icon>
      <h1>Invoice2Excel</h1>

      <v-spacer></v-spacer>
      <v-tabs right hide-slider v-if="$vuetify.breakpoint.smAndUp">
        <v-tab @click="goToExpansionPanel(0)">{{ $t('home.navbar.explanation') }}</v-tab>
        <v-tab @click="goToExpansionPanel(1)">{{ $t('home.navbar.faq') }}</v-tab>
        <v-tab @click="goToExpansionPanel(2)">{{ $t('home.navbar.contact') }}</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-navigation-drawer app v-model="showDrawer">
      <v-list>
        <v-list-item link @click="goToExpansionPanel(0); showDrawer = false">{{ $t('home.navbar.explanation') }}</v-list-item>
        <v-list-item link @click="goToExpansionPanel(1); showDrawer = false">{{ $t('home.navbar.faq') }}</v-list-item>
        <v-list-item link @click="goToExpansionPanel(2); showDrawer = false">{{ $t('home.navbar.contact') }}</v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="primary" fixed>
      <span style="color: white">&copy; 2021 Invoice2Excel.com</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'App',

  data: () => ({
    showDrawer: false
  }),
  methods: {
    ...mapMutations(['setOpenedExpansionPanels']),
    async goToExpansionPanel(index) {
      this.setOpenedExpansionPanels([index])
      await setTimeout(() => {
        if (index === 0){
          this.$vuetify.goTo('#explanation')
        } else if(index === 1) {
          this.$vuetify.goTo('#faq')
        } else if(index === 2) {
          this.$vuetify.goTo('#contact')
        }
      }, 300)
    }
  },
}
</script>
