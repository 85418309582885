

export default {
  namespaced: true,
  state: () => ({

  }),
  actions: {
    async sendContactMessage ({ state }, { email, subject, message }) {
      const body = {email, subject, message}
      const response = await fetch('/rest/contact/', {
        method: 'POST',
        body: JSON.stringify(body)
      })
    }
  },
  mutations: {}
}
