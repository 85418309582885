<template>
  <div class="home">
    <v-snackbar  color="primary" @input="showContactMessageSendSnackbar = false" :value="showContactMessageSendSnackbar">{{ $t('home.contact.snackbar') }}</v-snackbar>
    <v-card>
      <div :class="{'header-bar': $vuetify.breakpoint.smAndUp}">
        <div>
          <h1>{{ $t('home.title') }}</h1>
          <p v-html="$t('home.description')"></p>
        </div>
        <!-- TODO copyright of image? -->
        <v-img style="justify-self: end" src="../../assets/pdf_to_excel.png" contain max-height="100px" min-width="300px"/>
      </div>



      <v-row>
        <v-col sm='12' cols='12'>
          <DropArea
            @input="files => uploadFiles({ files })"
            :loading="loading"
            :progress="progress"/>
        </v-col>
      </v-row>

      <v-row v-for='(row, i) in gridbody' :key='i'>
        <v-col v-for='(cell, j) in row' :key='j' cols='12' md='4'>
          <GridCard :title='cell.title' :v-icon='cell.icon' :body='cell.body' />
        </v-col>
      </v-row>
      <v-expansion-panels multiple :value="openedExpansionPanels" class="expansion-panels">
        <v-expansion-panel id="explanation">
          <v-expansion-panel-header class="exp-title">{{this.$t('home.explanationText.title')}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            {{this.$t('home.explanationText.description')}}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel id="faq">
          <v-expansion-panel-header class="exp-title">{{this.$t('home.faq.title')}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3>1. {{$t('home.faq.howWork.title')}}</h3>
            <section>{{$t('home.faq.howWork.text')}}</section>
            <br>
            <h3>2. {{$t('home.faq.howLong.title')}}</h3>
            <section>{{$t('home.faq.howLong.text')}}</section>
            <br>
            <h3>3. {{$t('home.faq.howTo.title')}}</h3>
            <section>{{$t('home.faq.howTo.text')}}</section>
            <br>
            <h3>4. {{$t('home.faq.howMuch.title')}}</h3>
            <section>{{$t('home.faq.howMuch.text')}}</section>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel id="contact">
          <v-expansion-panel-header class="exp-title">{{this.$t('home.contact.title')}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <label>{{ $t('home.contact.email') }}</label>
            <v-text-field :rules="[value => value === '' || isEmail(value) || $t('home.contact.errors.emailFormat')]" @input="emailInvalid = false" v-model="email" outlined flat></v-text-field>
            <label>{{ $t('home.contact.subject') }}</label>
            <v-text-field v-model="subject" outlined flat></v-text-field>
            <label>{{ $t('home.contact.message') }}</label>
            <v-textarea ref="emailMessage" :rules="[value => !!value || $t('home.contact.errors.messageMissing')]" @input="messageMissing = false" v-model="message" outlined flat></v-textarea>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn @click="sendMessage" min-width="150px" color="primary">{{ $t('home.contact.send') }}</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DropArea from '../../components/DropArea'
import GridCard from './components/GridCard'
import isEmail from 'validator/lib/isEmail'

export default {
  name: 'Home',
  components: {
    DropArea,
    GridCard
  },
  computed: {
    ...mapState('Upload', {
      loading: state => state.loading,
      progress: state => state.progress
    }),
    ...mapState({
      openedExpansionPanels: state => state.openedExpansionPanels
    })
  },
  created () {
    const gridObj = []
    for (let i = 0; i < 2; i++) {
      const row = []
      gridObj[i] = row
      for (let j = 0; j < 3; j++) {
        const title = this.$t(`home.grid${i * 3 + j}.title`)
        const body = this.$t(`home.grid${i * 3 + j}.body`)
        const icon = this.$t(`home.grid${i * 3 + j}.icon`)

        row[j] = { title, body, icon }
      }
    }
    this.gridbody = gridObj
  },
  data: () => {
    return {
      isEmail: isEmail,
      gridbody: [],
      email: '',
      subject: '',
      message: '',
      emailInvalid: false,
      messageMissing: false,
      showContactMessageSendSnackbar: false
    }
  },
  methods: {
    ...mapActions('Upload', ['uploadFiles']),
    ...mapActions('Contact', ['sendContactMessage']),
    async sendMessage() {
      if(!this.validate()) {
        return
      }
      await this.sendContactMessage({email: this.email, subject: this.subject, message: this.message.trim()})
      this.showContactMessageSendSnackbar = true
      this.$refs.emailMessage.reset()
      this.message = ''
      this.email = ''
      this.subject = ''

    },
    validate() {
      if(this.email && !isEmail(this.email)) {
        this.emailInvalid = true
      }
      if(this.message === '') {
        this.messageMissing = true
        this.$refs.emailMessage.focus()
        this.$refs.emailMessage.blur()
      }
      return !this.messageMissing && !this.emailInvalid
    },
  }
}
</script>

<style lang="scss" scoped>

  .header-bar{
    display: grid;
    grid-template-columns: 3fr 1fr
  }

  .home > div {
    margin: 30px auto;
    padding: 20px;
    max-width: 1200px;
  }

  .expansion-panels{
    .exp-title{
      font-size: 1em;
    }
  }
</style>
