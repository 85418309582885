import Vue from 'vue'
import Vuex from 'vuex'
import UploadModule from './UploadModule'
import ContactModule from './ContactModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openedExpansionPanels: []
  },
  mutations: {
    setOpenedExpansionPanels(state, value) {
      Vue.set(state, 'openedExpansionPanels', value)
    }
  },
  actions: {
  },
  modules: {
    Upload: UploadModule,
    Contact: ContactModule
  }
})
